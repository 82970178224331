export default {
    methods: {
        callCordial() {
            (function(C,O,R,D,I,A,L){
              C.CordialObject=I,C[I]=C[I]||function(){(C[I].q=C[I].q||[]).push(arguments)};
              C[I].l=1*new Date,C[I].q=[],A=O.createElement(R);
              L=O.getElementsByTagName(R)[0],A.async=1,A.src=D,L.parentNode.insertBefore(A,L);
            })(window, document, "script", "//shop.scoutandnimble.com/track.v2.js", "crdl");

            crdl("connect", "scoutandnimble", {
              trackUrl: "//se.shop.scoutandnimble.com",
              connectUrl: "//d.shop.scoutandnimble.com",
              cookieDomain: "shop.scoutandnimble.com",
              cookieLife: 365
            });
        },
        trackEvent(event, properties) {
            // this.callCordial()
            crdl('event', event, properties)
        },
        modifyCart(cartData) {
            console.log("Cordial cart data start" + cartData)
            // this.callCordial()
            crdl('event', 'AddToCart', {})
            crdl('cart', 'clear')
            crdl('cartitem', 'add', cartData)
            console.log("Cordial cart data stop")
        },
        confirmOrder(orderData) {
            // this.callCordial()
            crdl('order', 'add', orderData)
            crdl('cart', 'clear')
            crdl('event', 'Purchase', {})
        },
        identifyCordialUser(email, cartData) {
            console.log('identifyCordialUser 1 ')
            // cordial.contact({
            //     channels: {
            //         email: {
            //             address: email,
            //             subscribeStatus: 'subscribed'
            //         }
            //     },
            //     Web_Signups: true
            // })
            // this.callCordial()
            crdl('contact', { email: email }, {
                channels: {
                    email: {
                        address: email,
                        subscribeStatus: 'subscribed'
                    }
                },
                Web_Signups_15off: true
            })
            crdl('cart', 'clear')
            crdl('cartitem', 'add', cartData)
            crdl('event', 'AddToCart', {})
            console.log('identifyCordialUser 2 ')
        },
        forgetCordialUser() {
            console.log('log out')
            // this.callCordial()
            crdl('forget')
        }
    }
}