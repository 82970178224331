import ApiOrder from '@/api/Order'
import { trim, toLower } from 'lodash'

export default function ({ store, $axios }) {
  function discountGenerationParams () {
    return { code: store.getters['order/getUrlCampaign'] }
  }

  function shouldGenerateDiscount () {
    return (
      !!store.getters['order/getOrder'] &&
      !!store.getters['order/getUrlCampaign'] &&
      !isPromotionApplicationDisabled() &&
      !isPromotionApplied() &&
      !isPromotionExpired()
    )
  }

  function isPromotionApplied () {
    let code = store.getters['order/getUrlCampaign'] || ''
        code = trim(code)
        code = toLower(code)

    return store.getters['order/getDiscountsAdjustments']
                .some(adjustment => adjustment.is_promotion && adjustment.label === code)
  }

  function isPromotionApplicationDisabled () {
    return store.$cookies.get('sanUCAD')
  }

  function isPromotionExpired () {
    const targetTimeString = store.$cookies.get('sanUCEA')
    if (!targetTimeString) return false

    return new Date(targetTimeString).getTime() <= Date.now()
  }

  function handleSuccess (response) {
    store.dispatch('order/setOrder', response.data.data)
  }

  function discountNotAvailableForUser (error) {
    const errors = error.response?.data?.errors ?? {}
    const errorsMessages = Object.values(errors).flat()

    return errorsMessages.some(errorMsg => /\nDetails: .*Single|FirstOrder/.test(errorMsg))
  }

  function handleErrors (error) {
    console.error(error)

    if (discountNotAvailableForUser(error)) {
      store.dispatch('order/clearUrlCampaign')
    }
  }

  return function generateDiscount () {
    if (!shouldGenerateDiscount()) return

    ApiOrder.generateDiscount($axios, store.getters['order/getOrderId'], discountGenerationParams())
      .then(handleSuccess)
      .catch(handleErrors)
  }
}