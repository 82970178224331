import Vue from 'vue'
import Vuex from 'vuex'

// import { imagePreview, productData, modal, globalData, auth, user, order, recentlyViewed, axiosConfig } from './modules'


const handles = ['main_desktop', 'main_right_desktop', 'footer_desktop', 'main_mobile', 'footer_mobile', 'shop_by_category']

import ApiMenu from "@/api/Menu";
import ApiState from '@/../shared/api/State'

import {store_state_plugin} from './plugins/store_state_plugin'

import createLogger from 'vuex/dist/logger'

// Vue.use(Vuex)

export const actions = {
    async nuxtServerInit({ commit }, { $axios }) {
        await ApiMenu.getAllMenusAtOnce($axios)
            .then(response => {
                commit('menu/setMenus', response.data)
            })
            .catch(error => {
                console.log('error' + error)
            })

        await ApiState.getStates($axios)
            .then(response => {
                commit('states/setStates', response.data.data)
            })
            .catch(error => {
                console.log('error' + error)
            })
    }
}