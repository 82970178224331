function setImgSrc (el, { value }) {
  if (value) { el.src = value }
}

function checkAndReplaceImgSrc (el, binding) {
  if(!el.src && !el.dataset.src) { setImgSrc(el, binding) }

  if (!el.onerror) {
      el.onerror = () => {
        setImgSrc(el, binding)
        el.onerror = null
      }
  }
}

export default {
  inserted: checkAndReplaceImgSrc,
  componentUpdated: checkAndReplaceImgSrc
}