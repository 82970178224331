<template>
  <sale-banner desktop-header-text="Buy more, save more!"
               mobile-header-text="Buy More, Save More"
              :tiers="buyMoreSaveMoreData.tiers"
               :offer-details-link="buyMoreSaveMoreData.offerDetailsLink"
               class="sale-banner"
               @close="close">
    <template #slide="{ tier, tierRange }">
      Spend {{ tierRange(tier) }}, get
      <span class="sale-banner__highlighted">
        {{ tier.amount }} off
      </span>
      your order*
    </template>
  </sale-banner>
</template>

<script>
import SaleBanner from '@/components/SaleBanner'
import buyMoreSaveMoreData from '@/constants/discountsData/buyMoreSaveMoreData'

export default {
  name: 'BuyMoreSaveMoreBanner',
  components: {
    SaleBanner
  },
  data() {
    return {
      buyMoreSaveMoreData
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/stylesheets/variables';

  .sale-banner {
    background: $dark-olive-green;
  }

  ::v-deep .sale-banner {
    background: $dark-olive-green;

    &__header-text {
      color: $pale-mint;
    }

    &__main {
      color: $color-white;
      width: 330px;
    }

    &__btn--offer-details {
      color: $soft-gray;
    }

    &__counter {
      color: $light-sage;

      .time-counter__part {
        color: $color-white;
      }

      .time-counter__unit {
        color: $light-sage;
      }
    }
  }
</style>