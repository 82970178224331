<template>
  <div :key="timeLeft"
       class="time-counter"
       v-html="`Ends&nbsp;&nbsp;&nbsp;${formattedTimeLeft}`">
  </div>
</template>

<script>
  import { padStart } from 'lodash'

  export default {
    name: 'TimeCounter',
    props: {
      targetTime: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        targetTimestamp: null,
        timeLeft: 0,
        timer: null
      }
    },
    computed: {
      formattedTimeLeft() {
        let hours = 0
        let minutes = 0
        let seconds = 0

        if (this.timeLeft) {
          hours = Math.floor(this.timeLeft / 3600)
          minutes = Math.floor((this.timeLeft % 3600) / 60)
          seconds = this.timeLeft % 60
        }

        return [
          this.createTimeLeftPartEl(hours, 'hrs'),
          this.createTimeLeftPartEl(minutes, 'min'),
          this.createTimeLeftPartEl(seconds, 'sec')
        ].join(' : ')
      }
    },
    mounted () {
      this.setTargetTime(this.targetTime)
      this.startTimer()
    },
    beforeDestroy() {
      if (this.timer) clearInterval(this.timer)
    },
    methods: {
      createTimeLeftPartEl (value, unit) {
        const { _scopeId } = this.$options

        return ''+
          `<span ${_scopeId} class="time-counter__part">${padStart(value, 2, '0')}</span>` +
          `<span ${_scopeId} class="time-counter__unit">&nbsp;${unit}</span>`
      },
      setTargetTime (targetDateTimeString) {
        const time = new Date(targetDateTimeString)
        this.targetTimestamp = time.getTime()
      },
      updateTimeLeft () {
        const now = Date.now()
        const diff = Math.floor((this.targetTimestamp - now) / 1000)

        if (diff <= 0) {
          this.timeLeft = 0
          clearInterval(this.timer)
        } else {
          this.timeLeft = diff
        }
      },
      startTimer () {
        this.updateTimeLeft()
        this.timer = setInterval(this.updateTimeLeft, 1000)
      }
    }
  }
</script>