<template>
  <div class="sale-banner">
    <button :class="[
              'sale-banner__btn',
              'sale-banner__btn--close',
              'sale-banner__desktop-only'
            ]">
      <img src="@/../shared/assets/images/close.svg"
           alt="Close Button"
           @click="close"/>
    </button>

    <div class="sale-banner__header">
      <button :class="[
                'sale-banner__btn',
                'sale-banner__btn--close'
              ]">
        <img src="@/../shared/assets/images/close.svg"
             alt="Close Button"
             @click="close"/>
      </button>
      <div class="sale-banner__header-text sale-banner__desktop-only">
        {{ desktopHeaderText }}
      </div>
      <div class="sale-banner__header-text sale-banner__mobile-only">
        {{ mobileHeaderText }}
      </div>
      <div/>
    </div>

    <vue-slick-carousel v-if="tiers.length"
                        v-bind="carouselProps">
      <div v-for="tier in tiers">
        <slot name="slide"
              :tier="tier"
              :tier-range="tierRange"/>
      </div>
    </vue-slick-carousel>

    <div class="sale-banner__footer">
      <time-counter v-if="targetTime"
                    class="sale-banner__counter"
                    :target-time="targetTime"/>
      <nuxt-link :to="offerDetailsLink"
                 class="sale-banner__btn sale-banner__btn--offer-details">
        *
        <span class="sale-banner__underline">
          Offer details
        </span>
      </nuxt-link>
    </div>

    <div class="sale-banner__desktop-only"/>
  </div>
</template>

<script>
import TimeCounter from '@/components/TimeCounter'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'SaleBanner',
  components: {
    TimeCounter,
    VueSlickCarousel
  },
  props: {
    desktopHeaderText: {
      type: String,
      required: true
    },
    mobileHeaderText: {
      type: String,
      required: true
    },
    offerDetailsLink: {
      type: [String, Object],
      default: null
    },
    tiers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      targetTime: null,
      carouselProps: {
        autoplay: true,
        arrows: false,
        swipe: false,
        class: ['sale-banner__main']
      }
    }
  },
  mounted() {
    this.initTargetTime()
  },
  methods: {
    initTargetTime() {
      this.targetTime = this.$cookies.get('sanUCEA')
    },
    close() {
      this.$emit('close')
    },
    tierRange({ range: { from, to } }) {
      if (from && to) return `${this.formatPrice(from)} to ${this.formatPrice(to)}`
      if (to) return `up to ${this.formatPrice(to)}`
      return `${this.formatPrice(from)} or more`
    },
    formatPrice(price) {
      return `$${price.toLocaleString()}`
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/stylesheets/variables';

  .sale-banner {
    font-family: 'MotivaSans';
    text-align: center;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-text {
        font-size: 1.6rem;
        font-weight: 700;
        padding-left: 23px;
      }
    }

    &__btn {
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: none;
      padding: 0;

      &--close {
        justify-self: right;
        background: none;
        display: flex;

        img {
          width: 15px;
          height: 15px;
          filter:
            invert(90%)
            sepia(19%)
            saturate(15%)
            hue-rotate(262deg)
            brightness(113%)
            contrast(70%);
        }
      }

      &--offer-details {
        font-size: 1rem;
        display: flex;
      }
    }

    &__main {
      width: 335px;
      font-size: 1.3rem;
      margin: 0 auto;
      white-space: wrap;
      max-width: calc(100vw - 30px);
    }

    ::v-deep &__highlighted {
      font-weight: 700;
    }

    &__footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: fit-content;
      margin: 0 auto;
      column-gap: 20px;
    }

    ::v-deep &__counter {
      font-size: 1.3rem;
      font-style: italic;

      .time-counter__part {
        color: $color-white;
        font-weight: 500;
      }

      .time-counter__unit {
        color: $pastel-pinkish-orange;
        text-transform: uppercase;
      }
    }

    &__underline {
      text-decoration: underline;
    }

    &__desktop-only {
      display: none;
    }

    &__mobile-only {
      display: block;
    }

    ::v-deep &__nowrap {
      white-space: nowrap;
    }

    @media (min-width: 852px) {
      grid-template-columns: 1fr repeat(3, auto) 1fr;
      align-items: end;
      justify-content: center;
      column-gap: 10px;

      &__header {
        &-text {
          font-size: 1.4rem;
        }

        .sale-banner {
          &__btn {
            &--close {
              display: none;
            }
          }
        }
      }

      &__footer {
        align-items: center;
      }

      &__btn {
        &--close {
          justify-self: flex-start;
          margin-right: auto;
        }
      }

      &__desktop-only {
        display: block;
      }

      &__mobile-only {
        display: none;
      }
    }
  }
</style>