var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sale-banner"},[_c('button',{class:[
            'sale-banner__btn',
            'sale-banner__btn--close',
            'sale-banner__desktop-only'
          ]},[_c('img',{attrs:{"src":require("@/../shared/assets/images/close.svg"),"alt":"Close Button"},on:{"click":_vm.close}})]),_vm._v(" "),_c('div',{staticClass:"sale-banner__header"},[_c('button',{class:[
              'sale-banner__btn',
              'sale-banner__btn--close'
            ]},[_c('img',{attrs:{"src":require("@/../shared/assets/images/close.svg"),"alt":"Close Button"},on:{"click":_vm.close}})]),_vm._v(" "),_c('div',{staticClass:"sale-banner__header-text sale-banner__desktop-only"},[_vm._v("\n      "+_vm._s(_vm.desktopHeaderText)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"sale-banner__header-text sale-banner__mobile-only"},[_vm._v("\n      "+_vm._s(_vm.mobileHeaderText)+"\n    ")]),_vm._v(" "),_c('div')]),_vm._v(" "),(_vm.tiers.length)?_c('vue-slick-carousel',_vm._b({},'vue-slick-carousel',_vm.carouselProps,false),_vm._l((_vm.tiers),function(tier){return _c('div',[_vm._t("slide",null,{"tier":tier,"tierRange":_vm.tierRange})],2)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sale-banner__footer"},[(_vm.targetTime)?_c('time-counter',{staticClass:"sale-banner__counter",attrs:{"target-time":_vm.targetTime}}):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"sale-banner__btn sale-banner__btn--offer-details",attrs:{"to":_vm.offerDetailsLink}},[_vm._v("\n      *\n      "),_c('span',{staticClass:"sale-banner__underline"},[_vm._v("\n        Offer details\n      ")])])],1),_vm._v(" "),_c('div',{staticClass:"sale-banner__desktop-only"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }