import Vue from 'vue'

function getGetter(getter, defaultValue) {
  return state.error ? defaultValue : getter.apply(this)
}

function getStringGetter (getter) {
  return getGetter(getter, '')
}

function getBooleanGetter (getter) {
  return getGetter(getter, false)
}

function getArrayGetter (getter) {
  return getGetter(getter, [])
}

function getNumberGetter (getter) {
  return getGetter(getter, 0)
}

function getObjectGetter (getter) {
  return getGetter(getter, {})
}

const getDefaultState = () => {
  return  {
    error: false,
    selectedVariant: '',
    canAddToCart: true,
    reviewsCount: {},
    reviewsStarAverage: {},
    asLowAsAmount: '$0',
    breadDisclaimer: '',
    selectedQuantity: 1
  }
}

export const state = getDefaultState()

export const getters = {
  getAsLowAsAmount (state) {
    return state.asLowAsAmount
  },
  getSelectedQuantity (state) {
    return state.selectedQuantity
  },
  getBreadDisclaimer (state) {
    return state.breadDisclaimer
  },
  getError (state) {
    return state.error
  },
  getSelectedVariant (state) {
    return state.selectedVariant
  },
  getCanAddToCart (state) {
    return state.canAddToCart
  },
  getReviewsCount (state) {
    return state.reviewsCount
  },
  getReviewsStarAverage (state) {
    return state.reviewsStarAverage
  },
  getMainVariantSlug (_state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getMainVariantSlug'])
  },
  getSlug (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getSlug'](state.selectedVariant))
  },
  getMetaTitle (_state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getSeoTitle'])
  },
  getMetaDescription (_state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getSeoDescription'])
  },
  getName (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() =>
      rootGetters['productData/getVariantName'](state.selectedVariant) ||
      rootGetters['productData/getName']
    )
  },
  getId (_state, _getters, _rootState, rootGetters) {
      return getStringGetter(() => rootGetters['productData/getId'])
  },
  getSku (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getSku'](state.selectedVariant))
  },
  getProductCare (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getProductCare'](state.selectedVariant))
  },
  getDescription (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getDescription'](state.selectedVariant)) || ''
  },
  getCategoryName (_state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getCategoryName'])
  },
  getCategoryPermalink (_state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getCategoryPermalink'])
  },
  getSupplierName (_state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getSupplierName'])
  },
  getSupplierSlug (_state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getSupplierSlug'])
  },
  getGiftCard (_state, _getters, _rootState, rootGetters) {
    return getBooleanGetter(() => rootGetters['productData/getGiftCard'])
  },
  getPrice (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getPrice'](state.selectedVariant))
  },
  getOnSale (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/onSale'](state.selectedVariant))
  },
  getSalePrice (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getSalePrice'](state.selectedVariant))
  },
  getCurrentPrice (state, _getters, _rootState, rootGetters) {
    let price = rootGetters['productData/getPrice'](state.selectedVariant)

    if (rootGetters['productData/onSale'](state.selectedVariant)) {
      price = rootGetters['productData/getSalePrice'](state.selectedVariant)
    }

    return getStringGetter(() => price)
  },
  discountPercentage (_state, getters) {
    let discount = ((1 - (getters.getSalePrice / getters.getPrice).toFixed(2)) * 100).toFixed(0)

    return discount
  },
  getImages (state, _getters, _rootState, rootGetters) {
    return getArrayGetter(() => rootGetters['productData/getImages'](state.selectedVariant))
  },
  getMainImage (state, getters, _rootState) {
    let mainImages = getters.getImages.filter(image => image['display_type'] === 'main')
    return mainImages.length > 0 ? mainImages[0]['images_url']['preview'] : ''
  },
  getInStock (state, _getters, _rootState, rootGetters) {
    return getBooleanGetter(() => rootGetters['productData/getQuantity'](state.selectedVariant) > 0)
  },
  getOGAvailability (state, _getters, _rootState, rootGetters) {
    if (state.error) {
      return ''
    } else if (rootGetters['productData/getDiscontinued'](state.selectedVariant)) {
      return 'discontinued'
    } else if ((rootGetters['productData/getQuantity'](state.selectedVariant) > 0)) {
      return 'instock'
    } else if (rootGetters['productData/getBackorderable'](state.selectedVariant)) {
      return 'backorder'
    } else {
      'out of stock'
    }
  },
  getSchemaOrgAvailability (state, _getters, _rootState, rootGetters) {
    if (state.error) {
      return ''
    } else if (rootGetters['productData/getDiscontinued'](state.selectedVariant)) {
      return 'https://schema.org/Discontinued'
    } else if ((rootGetters['productData/getQuantity'](state.selectedVariant) > 0)) {
      return 'https://schema.org/InStock'
    } else if (rootGetters['productData/getBackorderable'](state.selectedVariant)) {
      return 'https://schema.org/BackOrder'
    } else {
    'https://schema.org/SoldOut'
    }
  },
  getShippingCategory (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getShippingCategory'](state.selectedVariant))
  },
  getShowFreeShipping (_state, getters) {
    const price = getters.getOnSale ? getters.getSalePrice : getters.getPrice
    const freeShippingThreshold =
      ['freight', 'ltl_direct'].includes(getters.getShippingCategory)
        ? Vue.prototype.$freightFreeShippingTier
        : Vue.prototype.$smallParcelFreeShippingTier
    const result =
      getters.getShippingCategory.match(/_free$/) ||
      price >= freeShippingThreshold &&
      getters.getShippingCategory !== 'e_deliver'

    return result
  },
  getDiscountAmount (_state, getters) {
    return getters.getPrice - getters.getSalePrice
  },
  getPurchasable (state, _getters, _rootState, rootGetters) {
    return getBooleanGetter(() => rootGetters['productData/getPurchasable'](state.selectedVariant))
  },
  getBackorderable (state, _getters, _rootState, rootGetters) {
    return getBooleanGetter(() =>
      rootGetters['productData/getBackorderable'](state.selectedVariant) &&
      rootGetters['productData/getQuantity'](state.selectedVariant) === 0
    )
  },
  getBackorderableDate (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getBackorderableDate'](state.selectedVariant))
  },
  getMadeToOrder (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getMadeToOrder'](state.selectedVariant))
  },
  getLeadTime (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getLeadTime'](state.selectedVariant))
  },
  getQuantity (state, _getters, _rootState, rootGetters) {
    return getNumberGetter(() => rootGetters['productData/getQuantity'](state.selectedVariant))
  },
  isFreightVisibleShippingCategory (_state, getters, _rootState) {
    return !!getters.visibleShippingCategory.match(/^freight/)
  },
  isSmallParcelVisibleShippingCategory (_state, getters, _rootState) {
    return !!getters.visibleShippingCategory.match(/^small_parcel/)
  },
  getVisibleShippingCategory (_state, getters, _rootState) {
    return getters.getShippingCategory === 'ltl_direct' ? 'freight' : getters.getShippingCategory
  },
  getModel3dLink (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getModel3dLink'](state.selectedVariant))
  },
  getOptions (_state, _getters, _rootState, rootGetters) {
    return getObjectGetter(() => rootGetters['productData/getOptions']())
  },
  getMultivariantOptions (_state, _getters, _rootState, rootGetters) {
    return getObjectGetter(() => rootGetters['productData/getMultivariantOptions']())
  },
  getVariantProperties (state, _getters, _rootState, rootGetters) {
    if (state.error) { return [] }

    let array = rootGetters['productData/getProperties'](state.selectedVariant) || []
    let result = []
    let map = new Map()

    for (let item of array) {
      if (map.has(item.property.name)) { continue }

      map.set(item.property.name, true)
      result.push({ ...item });
    }

    return result
  },
  getAllVariantProperties (state, _getters, _rootState, rootGetters) {
    return getArrayGetter(() => rootGetters['productData/getVariantProperties'](state.selectedVariant))
  },
  getVisibleVariantProperties (_state, getters) {
    let skuProperty = {
      key: 'sku',
      value: getters.getSku
    }
    let supplierProperty = {
      key: 'brand',
      value: getters.getSupplierName,
      link: { name: 'brands-name', params: { name: getters.getSupplierSlug } }
    }
    let otherProperties =
      getters.getVariantProperties
        .filter(e => e.show && !['sku', 'brand'].includes(e.property.name))
        .map(({ id, value, property }) => {
          return { id, value, key: property.name }
        })

    return [
      skuProperty,
      supplierProperty,
      ...otherProperties
    ]
  },
  visibleShippingCategory (_state, getters) {
    return getters.getShippingCategory === 'ltl_direct' ? 'freight' : getters.getShippingCategory
  },
  getRecentlyViewedProducts (state, _getters, _rootState, rootGetters) {
    let products = getArrayGetter(() => rootGetters['recentlyViewed/getProducts'])
    products = products?.filter(product => product.id !== state.selectedVariant) ?? []

    return products
  },
  getRelatedCollections (state, _getters, _rootState, rootGetters) {
    return getArrayGetter(() => rootGetters['productData/getRelatedCollections'](state.selectedVariant))
  },
  getPromoCode (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getPromoCode'](state.selectedVariant))
  },
  getNonReturnable (state, _getters, _rootState, rootGetters) {
    return getBooleanGetter(() => rootGetters['productData/getNonReturnable'](state.selectedVariant))
  },
  getDisplayNonReturnable (state, _getters, _rootState, rootGetters) {
    return getBooleanGetter(() => rootGetters['productData/getDisplayNonReturnable'](state.selectedVariant))
  },
  getPromoPercentage (_state, getters, _rootState) {
    let promoCode = getters.getPromoCode
    let promoPercentage = '20'

    if (promoCode === 'summersunshine') {
      promoPercentage = '10'
    } else if (promoCode === 'greenthumb') {
      promoPercentage = '15'
    }

    return promoPercentage
  },
  getFreeShipCode (state, _getters, _rootState, rootGetters) {
    return getStringGetter(() => rootGetters['productData/getFreeShipCode'](state.selectedVariant))
  },
  getTopSeller (state, _getters, _rootState, rootGetters) {
    return getBooleanGetter(() => rootGetters['productData/getTopSeller'](state.selectedVariant))
  }
}

export const mutations = {
  resetState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  setError (state, payload) {
    state.error = payload
  },
  setSelectedVariant (state, payload) {
    state.selectedVariant = payload
  },
  setCanAddToCart (state, payload) {
    state.canAddToCart = payload
  },
  setReviewsCount (state, payload) {
    state.reviewsCount = payload
  },
  setReviewsStarAverage (state, payload) {
    state.reviewsStarAverage = payload
  },
  setAsLowAsAmount (state, payload) {
    state.asLowAsAmount = payload
  },
  setBreadDisclaimer (state, payload) {
    state.breadDisclaimer = payload
  },
  setSelectedQuantity (state, payload) {
    state.selectedQuantity = payload
  }
}

export const actions = {
  resetState ({ commit }) {
    commit('resetState')
  },
  setError ({ commit }, payload) {
    commit('setError', payload)
  },
  setSelectedVariant ({ commit }, payload) {
    commit('setSelectedVariant', payload)
  },
  setCanAddToCart ({ commit }, payload) {
    commit('setCanAddToCart', payload)
  },
  setReviewsCount ({ commit }, payload) {
    commit('setReviewsCount', payload)
  },
  setReviewsStarAverage ({ commit }, payload) {
    commit('setReviewsStarAverage', payload)
  },
  setAsLowAsAmount ({ commit }, payload) {
    commit('setAsLowAsAmount', payload)
  },
  setBreadDisclaimer ({ commit }, payload) {
    commit('setBreadDisclaimer', payload)
  },
  setSelectedQuantity ({ commit }, payload) {
    commit('setSelectedQuantity', payload)
  }
}
