<template>
  <sale-banner desktop-header-text="Buy more, get more!"
               mobile-header-text="Buy More, Get More"
               :tiers="buyMoreGetMoreData.tiers"
               :offer-details-link="buyMoreGetMoreData.offerDetailsLink"
               class="sale-banner"
               @close="close">
    <template #slide="{ tier, tierRange }">
      Spend {{ tierRange(tier) }}, get
      <span class="sale-banner__highlighted">
        {{ tier.amount }}%
      </span>
      back in Rewards Cash*
    </template>
  </sale-banner>
</template>

<script>
import SaleBanner from '@/components/SaleBanner'
import buyMoreGetMoreData from '@/constants/discountsData/buyMoreGetMoreData'

export default {
  name: 'BuyMoreGetMoreBanner',
  components: {
    SaleBanner
  },
  data () {
    return {
      buyMoreGetMoreData
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/stylesheets/variables';

  .sale-banner {
    background: $gunmetal;
  }

  ::v-deep .sale-banner {
    background: $gunmetal;

    &__header-text {
      color: $pastel-pinkish-orange;
    }

    &__main {
      color: $color-white;
      width: 388px;
    }

    &__btn--offer-details {
      color: $soft-gray;
    }

    &__counter {
      color: $pastel-pinkish-orange;

      .time-counter__part {
        color: $color-white;
      }

      .time-counter__unit {
        color: $pastel-pinkish-orange;
      }
    }

    @media(max-width: 410px) {
      &__main {
        width: calc(100vw);
        max-width: calc(100vw);
        margin: 0 -15px;
      }
    }

    @media(max-width: 380px) {
      &__main {
        font-size: 1.2rem;
      }
    }
  }
</style>