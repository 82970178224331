import ApiState from '@/../shared/api/State'

const states = {
  namespaced: true,
  getters: {
    getStates: state => {
      return state.states || []
    },
    getShippingStates: state => {
      return (state.states || []).filter(state => state.allowed_shipping)
    }
  },
  mutations: {
    setStates (state, payload) {
      state.states = payload
    }
  },
  actions: {
    loadStates ({ commit }, context) {
      ApiState.getStates(context.$axios)
        .then(response => {
          commit('setStates', response.data.data)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}

export { states }
