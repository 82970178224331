export const ADDRESS = '/api/v1'
export const AUTHADDRESS = ''
export const HEADERS = {'Content-Type': 'application/json'} //'Access-Control-Allow-Origin': '*'

export const CONFIG = {
    headers: {'Content-Type': 'application/json'},
    onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        console.log(percentCompleted)
    },
    onDownloadProgress: (progressEvent) => {
        console.log(progressEvent)
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        console.log(percentCompleted)
    }
}
