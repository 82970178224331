export default {
  label: 'Buy More, Get More',
  tiers: [
    { amount: 10, range: { to: 799.99 } },
    { amount: 15, range: { from: 800, to: 4999.99 } },
    { amount: 20, range: { from: 5000, to: 14999.99 } },
    { amount: 25, range: { from: 15000 } }
  ],
  termsAndConditionsLink: {
    name: 'pages-url_handler',
    params: {
      url_handler: 'bmgm-tos'
    }
  },
  offerDetailsLink: {
    name: 'pages-url_handler',
    params: {
      url_handler: 'rewards-cash-offer-details'
    }
  }
}